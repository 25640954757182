
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: "Trainne employment summary",
  components: {
    axios,
  
    Datatable,
    Swal,
   
  },
  data() {
    return {
      componentKey: 0,
      batch_info_id: this.$route.params.batch_info_id as any,
      is_3_month: this.$route.params.is_3_month as any,
      load: false,
      datatable: false,
      testData: [],
      trainee_info: '',
      tableData: [] as any,
      entity_name: '',
      institute_name: '',
      course_name: '',
      batch_number: '',
      total_trainee: '',
      female_trainee: '',
      institute_district: '',
      api_url: '',
      moment: "" as any,
    };
  },
  async created() {
    
    await this.getEmployeeList();
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    //this.testData = this.attendanceData;
  },
  methods: {

    async printPdf() {
      let formData = new FormData();

      formData.set('batch_info_id', this.batch_info_id);
      formData.set('is_3_month', this.is_3_month);
      let data = `${this.VUE_APP_API_URL}/api/trainee/employment_list_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },

    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('batch_info_id', this.batch_info_id);
      formData.set('is_3_month', this.is_3_month);
      await ApiService.post('trainee/employment_list_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('batch_info_id', this.batch_info_id);
      formData.set('is_3_month', this.is_3_month);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/employment_list_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'employment_list_export.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
  async getEmployeeList() {
      this.load = true;
      await ApiService.get('trainee/employment_list?batch_info_id=' +this.batch_info_id +'&is_3_month=' + this.is_3_month)
        .then((response) => {
          this.tableData = response.data.data.allTrainee;
          this.entity_name = response.data.data.entity_name;
          this.institute_name = response.data.data.institute_name;
          this.course_name = response.data.data.course_name;
          this.course_name = response.data.data.course_name;
          this.batch_number = response.data.data.batch_number;
          this.total_trainee = response.data.data.total_trainee;
          this.female_trainee = response.data.data.female_trainee;
          this.institute_district = response.data.data.institute_district;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
  },
});
